<template>
  <div class="home">
    <div class="content">
      <div class="title">{{ dataForm.title }}</div>
       <div class="ql-container ql-snow">
                  <div class="ql-editor">
                      <div class="iframe" v-html="dataForm.content"></div>
                  </div>
                </div>
   
  
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "book",
  data() {
    return {
      open: true,
      dataForm: {},
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    decode(base64) {
      // // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      return base64;
    },
    downloadFile(href, name) {
      // var blob = new Blob(['Hello, world!'], {
      //   type: 'text/plain;charset=utf-8',
      // });

      // 存在浏览器兼容性
      // let href = URL.createObjectURL(blob);

      let alink = document.createElement("a");
      alink.style.display = "none";
      // alink.download = name; //下载后文件名
      console.log(name, alink.download);
      alink.href = href;
      document.body.appendChild(alink);
      alink.click();
      document.body.removeChild(alink); //下载完成移除元素

      // URL.revokeObjectURL(href); //释放掉blob对象
    },
    getInfo() {
      this.$http
          .get(`/aboutus/getAboutUsById`, {params:{id:this.$route.query.id}})
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataForm = {
            ...res.data,
            content: this.decode(res.data.content),
            // catalogue:this.decode(res.data.catalogue),

            // attachUrl: JSON.parse(res.data.attachUrl),
          };
          console.log(this.dataForm, "dataform");
        })
        .catch((err) => { console.log(err, "dataform");});
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.home {
  padding: 0.32rem 0.64rem;
  padding-top: 1.16rem;

  .content {
    box-sizing: border-box;
    padding: 0.32rem 0.4rem;

    background: #ffffff;
    width: 13.12rem;

    border-radius: 0.02rem 0.02rem 0rem 0.02rem;
    .title {
      font-size: 0.2rem;
      text-align: center;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.28rem;
      margin-bottom: 0.24rem;
    }
    .msg {
      font-size: 0.14rem;
      text-align: center;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.22rem;
      margin-bottom: 0.24rem;
    }
    ul{
      margin-top: .7rem;
      border-bottom: 1px solid #EEEEEE;
      li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: .55rem;
        line-height: .55rem;
        border-top: 1px solid #EEEEEE;
        div{
          font-size: 0.14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.9);
          line-height: 0.22rem;
          img{
            width: 0.16rem;
            margin-left:.08rem
          }
          span{
            margin-left: 1.02rem;
          }
        }
        span{
          font-size: 0.14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.9);
          line-height: 0.22rem;
           
        }
        span:nth-child(1){

        }
      }
    }
    .iframe {
      /deep/p {
        font-size: 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgb(0, 0, 0);
        line-height: 0.22rem;
      }

      /deep/ img {
        max-width: 12rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .home {
    margin: 0;
    padding: 0;
    .content {
      margin: 0;
      padding: 0.15rem;
      margin-bottom: 0.5rem;
      width: auto;
      height: auto;
      .title {
        font-size: 0.16rem;

        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.24rem;
        margin-bottom: 0.16rem;
        text-align: left;
      }
      .msg {
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.22rem;
        margin-bottom: 0.16rem;
      }
    }
    .iframe{
        font-size: 0.12rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: rgba(0,0,0,0.9);
        line-height: 0.28rem;
    }
  }
}
</style>
